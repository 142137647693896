@font-face {
    font-family: TimesNewNormal;
    src: url("../media/times-new-normal.ttf");
}

body {
    background-image: url("../media/background.png");
}

.parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
}
