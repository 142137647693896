@font-face {
    font-family: TimesNewNormal;
    src: url("../media/times-new-normal.ttf");
}

body {
    background-image: url("../media/background.png");
}

.parent {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    flex-direction: column;
}

.isaacBackground {
    background-image: url("../media/isaac.png");
    width: 100vw;
    height: 100vh;
    background-size: 100vw, 100vh;
}

.rachelBackground {
    background-image: url("../media/rachel.jpg");
    width: 100vw;
    height: 100vh;
    background-size: 100vw, 100vh;
}

.annaBackground {
    background-image: url("../media/anna.jpg");
    width: 100vw;
    height: 100vh;
    background-size: 100vw, 100vh;
}

.scrollingParent {
    display: flex;
    justify-content: start;
    align-items: center;
    text-align: center;
    height: 100vh;
    flex-direction: column;
    overflow-y: auto;
    background: rgba(40, 44, 52, .85);
}

.darkParent {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    flex-direction: column;
    background: rgba(40, 44, 52, .5);
}

.horizontalOrganizer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 20px;
}

.centerBoxHome {
    border-radius: 30% 70% 72% 28% / 33% 31% 69% 67%;
    background-color: #282c34;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 65vw;
}

.innerContainer {
    display: flex;
    width: 80%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.box1 {
    border-radius: 30% 70% 10% 90% / 74% 20% 80% 26%;
    background-color: slategrey;
    transition: 0.2s;
    margin: 12px;
    text-decoration: none;
}

.box1:hover {
    border-radius: 35% 65% 27% 73% / 58% 27% 73% 42%;
    background-color: darkslateblue;
}

.box2 {
    border-radius: 71% 29% 70% 30% / 18% 42% 58% 82% ;
    background-color: midnightblue;
    transition: 0.2s;
    margin: 12px;
    text-decoration: none;
}

.box2:hover {
    border-radius: 35% 65% 27% 73% / 58% 27% 73% 42%;
    background-color: steelblue;
}

.box3 {
    border-radius: 30% 70% 70% 30% / 71% 63% 37% 29%;
    background-color: royalblue;
    transition: 0.2s;
    margin: 12px;
    text-decoration: none;
}

.box3:hover {
    border-radius: 21% 79% 12% 88% / 60% 55% 45% 40%  ;
    background-color: goldenrod;
}

.box4 {
    border-radius: 35% 65% 36% 64% / 58% 27% 73% 42% ;
    background-color: olivedrab;
    transition: 0.2s;
    margin: 12px;
    text-decoration: none;
}

.box4:hover {
    border-radius: 64% 36% 36% 64% / 58% 56% 44% 42% ;
    background-color: palevioletred;
}

.box5 {
    border-radius: 20% 80% 27% 73% / 85% 30% 70% 15%;
    background-color: olive;
    transition: 0.2s;
    margin: 12px;
    text-decoration: none;
}

.box5:hover {
    border-radius: 42% 58% 55% 45% / 85% 23% 77% 15%;
    background-color: #504148;
}

.headerTextHome {
    font-weight: 500;
    font-family: TimesNewNormal, serif;
    font-size: 8vh;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
}

.buttonText {
    font-weight: 500;
    font-family: TimesNewNormal, serif;
    font-size: 5vh;
    color: white;
    padding: 15px;
}
