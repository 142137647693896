@font-face {
    font-family: TimesNewNormal;
    src: url("../media/times-new-normal.ttf");
}

.homeButton {
    font-family: TimesNewNormal, serif;
    padding: 20px;
    text-decoration: none;
    color: white;
    border-radius: 32% 68% 18% 82% / 67% 39% 61% 33%;
    background-color: #5b7557;
    font-size: x-large;
    transition: 0.2s;
    border: none;
    margin: 10px;
}

.homeButton:hover {
    border-radius: 71% 29% 18% 82% / 57% 46% 54% 43% ;
    background-color: #854419;
}

.logoutButton {
    font-family: TimesNewNormal, serif;
    padding: 20px;
    text-decoration: none;
    color: white;
    border-radius: 16% 84% 18% 82% / 78% 29% 71% 22% ;
    background-color: #283c52;
    font-size: x-large;
    transition: 0.2s;
    border: none;
    margin: 10px;
}

.logoutButton:hover {
    border-radius: 74% 26% 69% 31% / 47% 75% 25% 53%  ;
    background-color: #4f0e37;
}

.personalBox {
    font-family: TimesNewNormal, serif;
    padding: 20px;
    color: white;
    max-width: 800px;
    text-align: left;
}

h1 {
    font-family: TimesNewNormal, serif;
    font-weight: 500;
    font-size: 10vh;
    color: white;
}

* {
    font-family: TimesNewNormal, serif;
    font-weight: 400;
    font-size: 3vh;
    min-font-size: 10px;
}
