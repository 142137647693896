.parent {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    flex-direction: column;
}

.centerBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #282c34;
    width: 25ch;
    border-radius: 42% 58% 55% 45% / 54% 23% 77% 46%;
    padding: 20px;
    margin: 10px;
}

.headerText {
    font-weight: 500;
    font-family: TimesNewNormal, serif;
    font-size: xx-large;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
}

.nameField {
    padding: 0.6em;
    width: 60%;
    border-radius: 42% 58% 55% 45% / 54% 23% 77% 46%;
    font-family: TimesNewNormal, serif;
    font-size: xx-large;
    text-align: center;
}

.submitButton {
    background-color: #0e378a;
    border-radius: 42% 58% 55% 45% / 54% 23% 77% 46%;
    padding: 20px;
    font-family: TimesNewNormal, serif;
    font-weight: 500;
    font-size: xx-large;
    color: white;
    transition: 0.2s;
    border: none;
}

.submitButton:hover {
    background-color: olivedrab;
    border-radius: 46% 54% 30% 70% / 47% 42% 58% 53% ;
}


